import { Component, Input } from '@angular/core';
import { SearchResultItemInputs } from '@engineering/vpgroove-angular';
import { GenericGrooveWrapperComponent } from '../generic-groove-wrapper/generic-groove-wrapper.component';
import {DropdownOptions} from "../../../../shared/dropdown-options.model";

@Component({
  selector: 'groove-dropdown-field',
  templateUrl: './groove-dropdown-field.component.html',
  styleUrls: ['./groove-dropdown-field.component.scss']
})
export class GrooveDropdownFieldComponent extends GenericGrooveWrapperComponent {
    @Input() data: DropdownOptions[] = [];
}
