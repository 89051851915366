import { Component, Input } from '@angular/core';
import { UntypedFormGroup, AbstractControl } from '@angular/forms';
import { FlexFormField } from 'src/app/shared/flex-forms/flex-form-field.class';
import { Constants } from 'src/app/shared/constants.class';
import { RegistrationDetails } from '../../shared/registration-details.class';
import { FlexFormAttributes } from "../../shared/flex-forms/flex-from-attributes";

@Component({
    selector: 'flex-form-field',
    templateUrl: './flex-form-field.component.html',
    styleUrls: ['./flex-form-field.component.scss']
})
export class FlexFormFieldComponent {

    @Input() field: FlexFormField;
    @Input() form: UntypedFormGroup;
    @Input() enrollmentGroupExternalId: string;
    @Input() registrationDetails: RegistrationDetails;
    @Input() progressData: string;
    @Input() flexFormAttributes: FlexFormAttributes;

    isFieldHidden: boolean = false;

    get COMPONENT_TYPE() {
        return Constants.COMPONENT_TYPE;
    }

    get isValid() {
        if (this.field.editable == false) {
            return true;
        } else {
            return this.form.controls[this.field.fieldName].valid;
        }
    }

    get formControl(): AbstractControl {
        return this.form.controls[this.field.fieldName];
    }

    handleFieldHidden(isFieldHidden: boolean) {
        this.isFieldHidden = isFieldHidden;
    }
}
