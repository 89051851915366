<vpg-dialog-header [closeButtonAriaLabel]="'enrollment.forms.agreements.closeAgreement' | translate: { agreementTitle: agreement?.name }">
    {{ agreement.name }}
</vpg-dialog-header>
<vpg-dialog-body class="agreement-dialog-body"
                 [innerHtml]="agreement.content"></vpg-dialog-body>
<vpg-dialog-actions class="agreement-accept-btn-container">
    <button vpg-button
            id="agreement-accept-btn"
            [textContent]="'enrollment.forms.Done' | translate"
            (click)="close()">
    </button>
</vpg-dialog-actions>
