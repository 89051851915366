<div>
    <div id="form-loading-spinnner" *ngIf="!flexForm && !errorMessage">
        <img src="assets/images/spinnerLoading.gif">
    </div>

    <form id="flex-form-form" [formGroup]="form" (ngSubmit)="onSubmit()"
        [ngClass]="{'visibility-hidden': !flexForm && !errorMessage}">

        <div id="flex-form-top-error" class="flex-form-top-error" *ngIf="flexForm?.attributes?.topFormError">
            <vpg-inline-label [variant]="'overdue'"
                              [id]="'top-error-message'"
                              [text]="flexForm?.attributes?.topFormError">
            </vpg-inline-label>
        </div>

        <div>
            <flex-form-header [pageType]="flexForm?.pageType" [title]="flexForm?.title"
                [description]="flexForm?.description"></flex-form-header>
        </div>

        <div id="multi-product-agreements-div"
            *ngIf="flexForm?.attributes?.productBannerImage || flexForm?.attributes?.agreementSubtitle || flexForm?.attributes?.agreementDescription">
            <img id="multi-product-agreements-banner-image" *ngIf="flexForm?.attributes?.productBannerImage"
                [src]="flexForm?.attributes?.productBannerImage" alt=""/>
            <span id="multi-product-agreements-subtitle"
                *ngIf="flexForm?.attributes?.agreementSubtitle">{{flexForm?.attributes?.agreementSubtitle}}</span>
            <span id="multi-product-agreements-description"
                *ngIf="flexForm?.attributes?.agreementDescription">{{flexForm?.attributes?.agreementDescription}}</span>
        </div>

        <div class="flex-form-fields-wrapper" *ngIf="flexForm?.fields?.length">
            <flex-form-field [id]="'flex-form-field-' + field.fieldName" class="flex-form-field-wrapper" [field]="field"
                [form]="form" [enrollmentGroupExternalId]="enrollmentGroupExternalId"
                *ngFor="let field of flexForm?.fields" [ngStyle]="getFlexStyle(field.columns)"
                [registrationDetails]="registrationDetails" [progressData]="flexForm.progressData" [flexFormAttributes]="flexForm.attributes">
            </flex-form-field>
        </div>
        <reactive-sponsor-search *ngIf="isSponsorSearchPage" [form]="form"></reactive-sponsor-search>

        <div id="flex-form-error" class="flex-form-error" *ngIf="errorMessage">
            <vpg-inline-label role="alert"
                              [id]="'flex-form-error-message'"
                              [variant]="'overdue'"
                              [text]="errorMessage"
                              [icon]="'exclamation-circle'">
            </vpg-inline-label>
        </div>

        <div id="submit-button-container" *ngIf="showSubmitButton">
            <button vpg-button
                    id="flex-form-submit-btn"
                    *ngIf="flexForm?.pageType && !pageTypesWithoutContinueButton.includes(flexForm?.pageType) && !isSubmitPending"
                    [type]="'submit'"
                    [attr.aria-label]="continueBtnTranslation | translate"
                    [textContent]="continueBtnTranslation | translate"
                    [disabled]="!form.valid">
            </button>
            <div *ngIf="isSubmitPending" id="submit-pending-spinner">
                <img src="assets/images/spinnerLoading.gif">
            </div>

            <div *ngIf="showSignInLink" id="sign-in-container">
                <span id="already-a-member-label" class="sign-in-already-a-member" tabindex="-1" translate="">enrollment.header.alreadyAMember</span>
                <a id="sign-in-link" [href]="signInLink" translate="" aria-labelledby="already-a-member-label sign-in-link">enrollment.header.signIn</a>
            </div>

            <div *ngIf="showTpaProviderSignInLink" id="provider-sign-in-container">
                <span id="provider-sign-in-label" class="sign-in-are-you-provider" tabindex="-1" translate="">enrollment.header.areYouProvider</span>
                <a id="provider-sign-in-link" [href]="tpaProviderSignInLink" translate="" aria-labelledby="provider-sign-in-label provider-sign-in-link">enrollment.header.signProviderHub</a>
            </div>
        </div>
    </form>
</div>
