import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { SponsorSettings } from './shared/sponsor-settings.class';
import { SponsorSettingsService } from './shared/sponsor-settings.service';
import { Theme, ThemeManagerService, ThemeProperties } from '@engineering/vpgroove-angular';
import { FeatureToggleService } from './shared/feature-toggle.service';
import { FeatureToggle } from './shared/feature-toggle.enum';

const vpFlexFormPhoto = 'https://file.personifyhealth.com/api/file/6te3IHgJS7Ct2XSVfJvl';
const phFlexFormPhoto = 'https://file.personifyhealth.com/api/file/8cmrufkQQoKStMjTIZC4';

@Component({
    selector: 'app',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
    coverPhoto = '';
    sponsorSettings: SponsorSettings;
    flexFormPhoto = '';

    @ViewChild('appContentArea', { static: true }) private appContentArea: ElementRef;

    constructor(
        private sponsorSettingsService: SponsorSettingsService,
        private themeManagerService: ThemeManagerService,
        private featureToggleService: FeatureToggleService
    ) {}

    ngOnInit() {
        this.setThemeAndBackgroundPhoto();

        this.sponsorSettingsService.getRegistrationDetails().subscribe((details) => {
            this.sponsorSettings = details?.sponsorSettings;
            this.setBackgroundPhoto();
        });

    }

    private setBackgroundPhoto() {
        if (this.sponsorSettings && this.sponsorSettings.coverPhotoUrl) {
            this.coverPhoto = this.sponsorSettings.coverPhotoUrl;
        } else if (this.coverPhoto !== this.flexFormPhoto) {
            this.coverPhoto = this.flexFormPhoto;
        } else {
            this.coverPhoto = this.flexFormPhoto;
        }
    }

    setThemeAndBackgroundPhoto() {
        const vpTheme: Theme = {
            name: 'vpEnrollment',
            themeProperties: {
                '--VPGrooveColor-ButtonBackgroundColor': '#042961',
                '--VPGrooveColor-ButtonTextColor': '#FFF',
                '--VPGrooveColor-LinkColor': '#3671E2'
            } as ThemeProperties
        }

        const phTheme: Theme = {
            name: 'phEnrollment',
            themeProperties: {
                '--VPGrooveColor-ButtonBackgroundColor': '#003C44',
                '--VPGrooveColor-ButtonTextColor': '#FFF',
                '--VPGrooveColor-LinkColor': '#003C44'
            } as ThemeProperties
        }

        this.featureToggleService.isFeatureToggleEnabled(FeatureToggle.ClientExperience2024_BrandingUpdatesPhase2)
            .subscribe((isRebrandingPhase2Enabled) => {
                if (isRebrandingPhase2Enabled) {
                    this.themeManagerService.registerTheme(phTheme);

                    this.flexFormPhoto = phFlexFormPhoto;
                    this.setBackgroundPhoto();

                    return;
                }

                this.themeManagerService.registerTheme(vpTheme);

                this.flexFormPhoto = vpFlexFormPhoto;
                this.setBackgroundPhoto();
            })
    }
}
