import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseFormField } from 'src/app/shared/flex-forms/base-form-field.class';
import { FlexFormField } from 'src/app/shared/flex-forms/flex-form-field.class';
import { FlexFormAgreementData } from "../../../../shared/flex-forms/flex-form-agreement-data.class";
import { DialogService, ModalActionInputs, ModalActionType, ModalType } from '@engineering/vpgroove-angular';
import { TranslateService } from '@ngx-translate/core';
import { AgreementModalComponent } from './agreement-modal/agreement-modal.component';

@Component({
    selector: 'reactive-agreement-checkbox',
    templateUrl: './reactive-agreement-checkbox.component.html',
    styleUrls: ['./reactive-agreement-checkbox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ReactiveAgreementCheckboxComponent),
            multi: true,
        }
    ]
})
export class ReactiveAgreementCheckboxComponent extends BaseFormField implements ControlValueAccessor {

    isAgreed: boolean = false;

    @Input()
    field: FlexFormField;

    @Input()
    form: UntypedFormGroup;

    @Input()
    enrollmentGroupExternalId: string;

    modalType = ModalType.HtmlModal;
    modalActionInputs: ModalActionInputs = {
        primaryButtonLabel: this.translateService.instant('enrollment.forms.Done'),
        modalActionType: ModalActionType.Primary
    };

    constructor(
        private modalService: DialogService,
        private translateService: TranslateService
    ) {
        super();
    }

    get agreement(): FlexFormAgreementData {
        let values = this.field?.values as FlexFormAgreementData[];
        return values?.length ? values[0] : null;
    }

    openModal() {
        this.modalService.open(AgreementModalComponent, { props: {
            agreement: this.agreement
        }});
    }

    writeValue(newIsAgreed: boolean) {
        this.isAgreed = newIsAgreed;
    }

    onChange($event) {
        this.isAgreed = $event;
        this.propagateChange(
            this.isAgreed ? this.agreement?.id : null
        );
    }

    onTouch() {
        this.propagateTouch();
    }
}
