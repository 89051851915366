import { Component, OnInit } from '@angular/core';
import { TranslationService } from '@engineering/vpgroove-angular';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Language } from 'src/app/shared/language.class';
import { LanguagesService } from 'src/app/shared/languages.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {DropdownOptions} from "../../../shared/dropdown-options.model";

@Component({
    selector: 'vp-language',
    templateUrl: './language.component.html',
    styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnInit {

    dropdownOptions: DropdownOptions[] = [];
    languages: Language[] = [];
    selectedLanguage: Language;
    form: UntypedFormGroup = new UntypedFormGroup({
        language: new UntypedFormControl(null)
    });

    constructor(
        private languagesService: LanguagesService,
        public translateService: TranslateService,
        public translationService: TranslationService
    ) { }

    ngOnInit(): void {
        this.languagesService.getLanguages().toPromise().then((languages: Language[]) => {
            this.dropdownOptions = languages.map((language) => {
                return { text: language.description, textAriaLabel: language.englishNative };
            });

            this.languages = languages;
            this.selectedLanguage = this.languages.find((language) => {
                return language.code == (this.translateService.currentLang || this.translateService.getDefaultLang());
            });

            this.form.controls['language'].setValue(this.selectedLanguage.description);

            this.translateService.use(this.selectedLanguage.code);
            this.translationService.setTranslation('NoResultsHeader', this.translateService.instant('enrollment.forms.errors.noResultsFound'));
            this.translationService.setTranslation('NoResultsText', this.translateService.instant('enrollment.forms.errors.tryChangingSearchTerm'));

            this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
                let newLanguage = this.languages.find(language => language.code == event.lang);
                if (newLanguage) {
                    this.selectedLanguage = newLanguage;
                    moment.locale(event.lang);
                    this.translationService.setTranslation('NoResultsHeader', this.translateService.instant('enrollment.forms.errors.noResultsFound'));
                    this.translationService.setTranslation('NoResultsText', this.translateService.instant('enrollment.forms.errors.tryChangingSearchTerm'));
                }
            });

            this.form.controls['language'].valueChanges.subscribe((languageValue) => {
                this.onChange(languageValue);
            });
        });
    }

    onChange(languageDescription: string) {
        this.selectedLanguage = this.languages.find((language) => {
            return language.description === languageDescription;
        });

        if (this.selectedLanguage) {
            this.translateService.use(this.selectedLanguage.code);
            document.querySelector('html').setAttribute('lang', this.selectedLanguage.code.split('-')[0]);
        }
    }
}
