import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SearchResultItemInputs } from '@engineering/vpgroove-angular';
import { Constants } from 'src/app/shared/constants.class';
import { Country } from 'src/app/shared/country.class';
import { CountryService } from 'src/app/shared/country.service';
import { BaseFormField } from 'src/app/shared/flex-forms/base-form-field.class';
import { FlexFormDropdownData } from 'src/app/shared/flex-forms/flex-form-dropdown-data.class';
import { FlexFormField } from 'src/app/shared/flex-forms/flex-form-field.class';
import {DropdownOptions} from "../../../../shared/dropdown-options.model";

@Component({
  selector: 'reactive-state-of-residence',
  templateUrl: './reactive-state-of-residence.component.html',
  styleUrls: ['./reactive-state-of-residence.component.scss'],
  providers: [
    {
        provide: NG_VALUE_ACCESSOR,
        multi: true,
        useExisting: forwardRef(() => ReactiveStateOfResidenceComponent)
    }
]
})
export class ReactiveStateOfResidenceComponent extends BaseFormField implements OnInit, ControlValueAccessor {

    states: FlexFormDropdownData[] = [];
    grooveOptions: DropdownOptions[] = [];
    selectedState: FlexFormDropdownData = null;

    placeholderState: FlexFormDropdownData = new FlexFormDropdownData();
    isCountryUSA: boolean = true;

    @Input() field: FlexFormField;
    @Output() isFieldHidden = new EventEmitter<boolean>(true);

    constructor(
        public countryService: CountryService
    ) {
        super();
    }

    ngOnInit() {
        this.placeholderState.id = Constants.PLACEHOLDER_STATE_ID;
        this.states = this.field?.values as FlexFormDropdownData[];
        this.grooveOptions = this.states?.map((option) => {
            return { text: option.name };
        });

        if (this.field?.initialValue) {
            this.selectedState = this.states?.find((option) => {
                return option.id === this.field?.initialValue
            });
        }

        this.countryService.getSelectedCountry().subscribe((country: Country) => {
            this.isCountryUSA = (country?.countryCode === Constants.USA_COUNTRY_CODE);
            this.isFieldHidden.emit(!this.isCountryUSA);

            if (!this.isCountryUSA) {
                this.saveState(this.placeholderState);
            } else if (this.selectedState?.id == Constants.PLACEHOLDER_STATE_ID || this.selectedState?.id === null) {
                this.saveState(null);
            } else {
                this.saveState(this.selectedState);
            }
        });
    }

    saveState(state: FlexFormDropdownData) {
        this.propagateChange(state?.id);
    }

    onChange(name: string) {
        this.selectedState = this.states?.find((option) => {
            return option.name === name;
        });

        this.saveState(this.selectedState);
    }

    onTouch() {
        this.propagateTouch();
    }

    writeValue(id: string) {
        this.selectedState = this.states?.find((option) => {
            return option.id === id;
        });
    }
}
