import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseFormField } from '../../../../shared/flex-forms/base-form-field.class';
import { FlexFormField } from '../../../../shared/flex-forms/flex-form-field.class';
import { ButtonType, IconClassName } from '@engineering/vpgroove-angular';
import { PasswordService } from '../../../../shared/password.service';
import { FlexFormEnrollmentSuccessRedirectData } from '../../../../shared/flex-forms/flex-form-enrollment-success-redirect-data.class';
import { KeycloakLoginService } from '../../../../shared/authentication/keycloak-login.service';
import { FlexFormAttributes } from "../../../../shared/flex-forms/flex-from-attributes";

@Component({
    selector: 'reactive-enrollment-success-confirmation',
    templateUrl: './reactive-enrollment-success-confirmation.component.html',
    styleUrls: ['./reactive-enrollment-success-confirmation.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => ReactiveEnrollmentSuccessConfirmationComponent)
        }
    ]
})
export class ReactiveEnrollmentSuccessConfirmationComponent extends BaseFormField implements OnInit, ControlValueAccessor {
    @Input() form: UntypedFormGroup;
    @Input() field: FlexFormField;
    @Input() flexFormAttributes: FlexFormAttributes;

    redirectUrl = '';
    username = '';
    password = '';
    isAbbreviatedEnrollment = false;
    identityProviderType = ''

    btnlabel = '';
    btnAriaLabel = '';

    completeIconClassName = IconClassName.Check;

    constructor(
        private passwordService: PasswordService,
        private keycloakLoginService: KeycloakLoginService
    ) {
        super();
    }

    writeValue(): void {}

    ngOnInit(): void {
        if (this.field?.values?.length > 0) {
            const redirectData = this.field.values[0] as FlexFormEnrollmentSuccessRedirectData;
            this.redirectUrl = redirectData.redirectUrl;
            this.username = redirectData.username;
            this.btnlabel = redirectData.name;
            this.btnAriaLabel = redirectData.name;
            this.isAbbreviatedEnrollment = redirectData.isAbbreviatedEnrollment;
            this.identityProviderType = this.field.identityApiProvider
        }

        this.password = this.passwordService.getPassword();

        if (this.isAbbreviatedEnrollment) {
            const delayMs = this.flexFormAttributes?.abbreviatedLoginDelayMs || 0;
            if (delayMs) {
               setTimeout(() => this.initiateLogin(), delayMs);
            } else {
                this.initiateLogin();
            }
        }
    }

    initiateLogin(): void {
        if (this.username && this.password) {
            this.keycloakLoginService.setLoginProviderTypeToKeycloak(this.identityProviderType);
            this.keycloakLoginService.logInWithProvider(this.username, this.password, this.identityProviderType).subscribe(
                () => this.goToRedirectUrl(),
                () => this.goToRedirectUrl()
            );
        } else {
            this.goToRedirectUrl();
        }
    }

    private goToRedirectUrl() {
        window.location.href = this.redirectUrl;
    }
}
