import { Component, Input } from '@angular/core';
import { InputType } from '@engineering/vpgroove-angular/domain/types';
import { GenericGrooveWrapperComponent } from '../generic-groove-wrapper/generic-groove-wrapper.component';

@Component({
    selector: 'groove-password-field',
    templateUrl: './groove-password-field.component.html',
    styleUrls: ['./groove-password-field.component.scss']
})
export class GroovePasswordFieldComponent extends GenericGrooveWrapperComponent {

    @Input()
    placeholder: string = '';

    @Input()
    additionalAssistiveText: string = '';

    iconAriaLabel: string = 'enrollment.forms.arialLabelPasswordHidden';

    fieldType: InputType = 'password'

    passwordVisible = false;

    togglePasswordVisible() {
        this.passwordVisible = !this.passwordVisible;
        this.fieldType = this.passwordVisible ? 'text' : 'password';
    }
}
