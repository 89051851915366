import { Component, Input } from '@angular/core';
import { GenesisSponsorService } from '../../../../shared/genesis-sponsor.service';
import { UntypedFormGroup } from '@angular/forms';
import { GenesisSponsor } from '../../../../shared/genesis-sponsor.class';
import { environment } from '../../../../../environments/environment';
import { SearchResultItem } from "@engineering/vpgroove-angular";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'reactive-sponsor-search',
    templateUrl: './reactive-sponsor-search.component.html',
})

export class ReactiveSponsorSearchComponent {
    MIN_CHARACTERS = 2;

    @Input() form = new UntypedFormGroup({});

    sponsorSearchData: SearchResultItem[] = [];
    sponsors: GenesisSponsor[] = [];
    showNotFoundMessage = false;

    paging = {
        page: 0,
        pageSize: 25
    }

    constructor(
        private genesisSponsorService: GenesisSponsorService,
        private translateService: TranslateService
    ) { }

    selectSponsor($event) {
        const sponsor = this.sponsors.find((sponsor) => sponsor.name === $event);
        if (sponsor) {
            const language = this.translateService.currentLang || this.translateService.getDefaultLang()
            const baseUrl = this.setBaseUrlForEnrollmentGroupSelectionPage(sponsor.baseEnrollmentUrl);
            window.location.href = baseUrl + `/#/sponsor/${sponsor.guid}/enrollment-groups?language=${language}`;
        }
    }

    searchSponsors($event: any) {
        if (!$event || $event.length < this.MIN_CHARACTERS) {
            this.sponsorSearchData = [];
            this.sponsors = [];
            this.showNotFoundMessage = false;
            return;
        }

        this.genesisSponsorService.getSponsorsPage($event, this.paging, true).subscribe((response) => {
            const totalSponsors = parseInt(response.headers.get('content-items'));
            if (!totalSponsors) {
                this.sponsorSearchData = [];
                this.sponsors = [];
                this.showNotFoundMessage = true;
            }
            else {
                this.sponsors = response.body;
                this.sponsorSearchData = response.body.map((sponsor) => {
                    return { name: sponsor?.name };
                });
                this.showNotFoundMessage = false;
            }
        });
    }

    setBaseUrlForEnrollmentGroupSelectionPage(baseEnrollmentUrl: string): string {
        if (window.location.host.includes('localhost')) {
            return 'http://' + window.location.host
        }

        return baseEnrollmentUrl
    }
}
