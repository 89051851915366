import { Component, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IconClassName } from '@engineering/vpgroove-angular';
import { TranslateService } from '@ngx-translate/core';
import { BaseFormField } from 'src/app/shared/flex-forms/base-form-field.class';
import { FlexFormField } from 'src/app/shared/flex-forms/flex-form-field.class';
import { FlexFormProductData } from 'src/app/shared/flex-forms/flex-form-product-data.class';
import { FlexFormService } from 'src/app/shared/flex-forms/flex-form.service';

@Component({
    selector: 'reactive-product-select',
    templateUrl: './reactive-product-select.component.html',
    styleUrls: ['./reactive-product-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ReactiveProductSelectComponent),
            multi: true,
        }
    ]
})
export class ReactiveProductSelectComponent extends BaseFormField implements ControlValueAccessor, OnChanges {
    completeIconClassName = IconClassName.Check;
    product: FlexFormProductData;

    @Input()
    field: FlexFormField;

    @Input()
    form: UntypedFormGroup;

    @Input()
    progressData: string;

    @Input()
    step: number;

    constructor(
        private flexFormService: FlexFormService,
        public translateService: TranslateService
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.field) {
            let products = changes.field?.currentValue?.values as FlexFormProductData[];

            if (products?.length) {
                this.product = products[0];
            } else {
                this.product = null;
            }
        }

        this.propagateChange(this.product?.accepted);
    }

    goToAgreement() {
        if (!this.product.accepted) {
            this.flexFormService.submitOtherForm(
                { progressData: this.progressData },
                this.product.submitUrl,
                this.translateService.currentLang || this.translateService.defaultLang
            );
        }
    }

    writeValue(_: boolean) {}

    onTouch() {
        this.propagateTouch();
    }
}
