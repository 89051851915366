import { Component, Input, forwardRef, Output, EventEmitter, AfterContentInit } from '@angular/core';
import { BaseFormField } from 'src/app/shared/flex-forms/base-form-field.class';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SponsorCompaniesService } from 'src/app/shared/sponsor-companies.service';
import { FlexFormField } from 'src/app/shared/flex-forms/flex-form-field.class';
import { SearchResultItemInputs } from '@engineering/vpgroove-angular';
import {DropdownOptions} from "../../../../shared/dropdown-options.model";

@Component({
    selector: 'base-org-hierarchy-unit',
    templateUrl: './base-org-hierarchy-unit.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BaseOrgHierarchyUnitComponent),
            multi: true,
        }
    ]
})
export class BaseOrgHierarchyUnitComponent<T> extends BaseFormField implements ControlValueAccessor, AfterContentInit {

    values: Array<T>;
    grooveOptions: DropdownOptions[] = [];
    selectedValue: T;

    type: string;
    initialized: boolean = false;

    @Input() field: FlexFormField;
    @Output() isFieldHidden = new EventEmitter<boolean>(true);

    constructor(public sponsorCompaniesService: SponsorCompaniesService) {
        super();
    }

    ngAfterContentInit() {
        this.sponsorCompaniesService.getSubjectByType(this.type).subscribe((values: Array<T>) => {
            this.selectedValue = null;
            this.saveSelectedValue();

            this.values = values;
            this.grooveOptions = this.values?.map((value: any) => {
                return { text: value?.name };
            });

            if (this.field?.initialValue && !this.initialized) {
                this.selectedValue = this.getById(this.field?.initialValue);
                this.saveSelectedValue();
            }

            if (this.values?.length === 1) {
                this.selectedValue = this.values[0];
                this.saveSelectedValue();
                this.isFieldHidden.emit(true);
            } else if (!this.values) {
                this.isFieldHidden.emit(true);
            } else {
                this.isFieldHidden.emit(false);
            }

            this.initialized = true;
        });
    }

    saveSelectedValue() {
        if (this.selectedValue && this.selectedValue['id']) {
            this.propagateChange(this.selectedValue['id']);
        } else {
            this.propagateChange(null);
        }
    }

    onChange(name: string) {
        this.selectedValue = this.getByName(name);
        this.saveSelectedValue();
    }

    onTouch() {
        this.propagateTouch();
    }

    writeValue(id: string) {
        this.selectedValue = this.getById(id);
    }

    private getById(id: string) {
        return this.values?.find((value: T) => {
            return value['id'] === id;
        });
    }

    private getByName(name: string) {
        return this.values?.find((value: T) => {
            return value['name'] === name;
        });
    }
}
