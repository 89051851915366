import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, UntypedFormGroup, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { Constants } from 'src/app/shared/constants.class';
import { BaseFormField } from 'src/app/shared/flex-forms/base-form-field.class';
import { FlexFormField } from 'src/app/shared/flex-forms/flex-form-field.class';
import { PasswordWithConfirmation } from 'src/app/shared/flex-forms/password-with-confirmation.class';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'reactive-password',
    templateUrl: './reactive-password.component.html',
    styleUrls: ['./reactive-password.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ReactivePasswordComponent),
            multi: true,
        }
    ]
})
export class ReactivePasswordComponent extends BaseFormField implements ControlValueAccessor {

    DOT_ICON: string = 'assets/images/icon_dot.svg';
    FAIL_ICON: string = 'assets/images/Icon_fail.svg';
    PASS_ICON: string = 'assets/images/icon_pass_darker.svg';

    passwordWithConfirmation = new PasswordWithConfirmation();
    password: string = null;
    passwordConfirmation: string = null;
    passwordConfirmationTouched = false;

    @Input()
    field: FlexFormField;

    @Input()
    form: UntypedFormGroup;

    constructor(
        private translateService: TranslateService
    ) {
        super();
    }

    get fieldErrors(): ValidationErrors {
        return this.form?.controls[this.field?.fieldName]?.errors ?? {};
    }

    get VALIDATORS() {
        return Constants.VALIDATORS;
    }

    get hasPasswordFieldError(): boolean {
        return [
            this.VALIDATORS.UPPERCASE,
            this.VALIDATORS.LOWERCASE,
            this.VALIDATORS.NUMBER,
            this.VALIDATORS.SYMBOL,
            this.VALIDATORS.PASSWORD_LENGTH
        ].some(error => this.fieldErrors[error]);
    }

    writeValue(newPassword: PasswordWithConfirmation) {
        if (newPassword) {
            this.passwordWithConfirmation = newPassword;
        }
    }

    onChange() {
        this.passwordWithConfirmation.password = this.password;
        this.passwordWithConfirmation.matchesConfirmation = (this.password === this.passwordConfirmation)
        this.propagateChange(this.passwordWithConfirmation);
    }

    onPasswordChange(newValue: any) {
        this.password = newValue;
        this.onChange();
    }

    onConfirmationChange(newValue: any) {
        this.passwordConfirmation = newValue;
        this.onChange();
    }

    onPasswordTouch() {
        this.propagateTouch();
    }

    onConfirmationTouch() {
        this.passwordConfirmationTouched = true;
        this.propagateTouch();
    }

    getPasswordRequirementStatusIcon(error: boolean): string {
        if (!this.password) {
            return this.DOT_ICON;
        }

        return error ? this.FAIL_ICON : this.PASS_ICON;
    }

    getScreenReaderRequirementsNotMetMessage(): string {
        let requirementsNotMetMessage = '';

        if (this.fieldErrors[Constants.VALIDATORS.UPPERCASE]) {
            requirementsNotMetMessage += this.translateService.instant('enrollment.flex.requirementNotMet', { requirement: this.translateService.instant('enrollment.forms.passwordHelp.oneUpperCase') }) + ' ';
        }

        if (this.fieldErrors[Constants.VALIDATORS.LOWERCASE]) {
            requirementsNotMetMessage += this.translateService.instant('enrollment.flex.requirementNotMet', { requirement: this.translateService.instant('enrollment.forms.passwordHelp.oneLowerCase') }) + ' ';
        }

        if (this.fieldErrors[Constants.VALIDATORS.NUMBER]) {
            requirementsNotMetMessage += this.translateService.instant('enrollment.flex.requirementNotMet', { requirement: this.translateService.instant('enrollment.forms.passwordHelp.oneNumber') }) + ' ';
        }

        if (this.fieldErrors[Constants.VALIDATORS.SYMBOL]) {
            requirementsNotMetMessage += this.translateService.instant('enrollment.flex.requirementNotMet', { requirement: this.translateService.instant('enrollment.forms.passwordHelp.oneSpecialCharacter') }) + ' ';
        }

        if (this.fieldErrors[Constants.VALIDATORS.PASSWORD_LENGTH]) {
            requirementsNotMetMessage += this.translateService.instant('enrollment.flex.requirementNotMet', { requirement: this.translateService.instant('enrollment.forms.passwordHelp.lengthSpecifications') }) + ' ';
        }

        return requirementsNotMetMessage;
    }
}
