<div class="flex-center">
    <div id="flex-form-page-wrapper">
        <div id="header-panel">
            <div class="icon-container-small">
                <img id="company-icon-small" *ngIf="icon !== null"
                     [src]="icon"
                     [alt]="'enrollment.screenReader.sponsorNameLogo' | translate: { sponsorName: sponsorName || '' }"
                     aria-hidden="false">
            </div>
            <div class="language-dropdown">
                <vp-language [ngClass]="{'visibility-hidden': !isLanguageDropdownVisible}"
                             id="language-dropdown">
                </vp-language>
            </div>
        </div>
        <div id="progress-and-main-panel-wrapper">
            <div id="progress-panel">
                <div id="progress-icon-container">
                    <div class="icon-container-large">
                        <img id="company-icon-large" *ngIf="icon !== null"
                             [src]="icon"
                             [alt]="'enrollment.screenReader.sponsorNameLogo' | translate: { sponsorName: sponsorName || '' }"
                             aria-hidden="false">
                    </div>
                </div>
                <div *ngIf="!leftPanelImageUrl || !enrollmentGroupExternalId">
                    <h1 id="progress-title" translate="">enrollment.flex.progress.title</h1>
                    <div id="progress-description">{{ sidebarIntro }}</div>
                    <div *ngIf="flexFormProgressSteps?.length" id="progress-bar-wrapper">
                        <progress-bar [steps]="flexFormProgressSteps"></progress-bar>
                    </div>
                </div>
                <img id="left-panel-image-url" *ngIf="leftPanelImageUrl" [src]="leftPanelImageUrl" alt="">
            </div>

            <div id="main-panel">
                <div id="flex-form-page" class="form-group">
                    <flex-form class="container-fluid" id="flex-form" [enrollmentGroupExternalId]="enrollmentGroupExternalId" [redirectedFrom]="redirectedFrom"
                        [emailToken]="emailToken" [eligibilityToken]="eligibilityToken" [ssoRedirectPath]="ssoRedirectPath"
                        (stepDataEvent)="setProgressSteps($event)" (leftPanelImageEvent)="setLeftPanelImageUrl($event)"
                        (sidebarIntroEvent)="setSidebarIntro($event)" (logoUrlEvent)="setLogoUrl($event)"
                        (languageDropdownVisibleEvent)="setIsLanguageDropdownVisible($event)"
                        [sponsorExternalId]="sponsorExternalId">
                    </flex-form>
                </div>
            </div>
        </div>
    </div>
</div>
