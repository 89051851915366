import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SearchResultItemInputs } from '@engineering/vpgroove-angular';
import { Country } from 'src/app/shared/country.class';
import { CountryService } from 'src/app/shared/country.service';
import { BaseFormField } from 'src/app/shared/flex-forms/base-form-field.class';
import { FlexFormField } from 'src/app/shared/flex-forms/flex-form-field.class';
import {DropdownOptions} from "../../../../shared/dropdown-options.model";

@Component({
    selector: 'reactive-country',
    templateUrl: './reactive-country.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => ReactiveCountryComponent)
        }
    ]
})
export class ReactiveCountryComponent extends BaseFormField implements OnInit, ControlValueAccessor {

    countries: Country[] = [];
    grooveOptions: DropdownOptions[] = [];
    selectedCountry: Country;

    @Input() field: FlexFormField;

    constructor(public countryService: CountryService) {
        super();
    }

    ngOnInit(): void {
        this.countries = this.field?.values as Country[];
        this.grooveOptions = this.countries?.map((country) => {
            return { text: country?.name };
        });

        this.countryService.getSelectedCountry().subscribe((newCountry) => {
            this.onServiceChange(newCountry);
        });
    }

    onDropdownChange(newCountryName: string) {
        let newCountry = this.getCountryByName(newCountryName);
        this.saveToDropdown(newCountry);
        this.saveToForm(newCountry);
        this.saveToService(newCountry);
    }

    onFormChange(newId: number) {
        let newCountry = this.getCountryById(newId);
        this.saveToDropdown(newCountry);
        this.saveToService(newCountry);
    }

    onServiceChange(newCountry: Country) {
        this.saveToDropdown(newCountry);
        this.saveToForm(newCountry);
    }

    saveToDropdown(newCountry: Country) {
        if (this.selectedCountry?.id !== newCountry?.id) {
            this.selectedCountry = newCountry;
        }
    }

    saveToForm(newCountry: Country) {
        this.propagateChange(newCountry?.id);
    }

    saveToService(newCountry: Country) {
        this.countryService.setSelectedCountry(newCountry);
    }

    onChange(newCountryName: string) {
        this.onDropdownChange(newCountryName);
    }

    onTouch() {
        this.propagateTouch();
    }

    writeValue(id: number) {
        this.onFormChange(id);
    }

    private getCountryById(id: number) {
        return this.countries?.find((option) => {
            return option?.id === id;
        });
    }

    private getCountryByName(name: string) {
        return this.countries?.find((option) => {
            return option?.name === name;
        });
    }
}
