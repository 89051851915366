import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { SearchResultItemInputs } from '@engineering/vpgroove-angular';
import { BaseFormField } from 'src/app/shared/flex-forms/base-form-field.class';
import { FlexFormDropdownData } from 'src/app/shared/flex-forms/flex-form-dropdown-data.class';
import { FlexFormField } from 'src/app/shared/flex-forms/flex-form-field.class';
import {DropdownOptions} from "../../../../shared/dropdown-options.model";

@Component({
  selector: 'reactive-dropdown',
  templateUrl: './reactive-dropdown.component.html',
  styleUrls: ['./reactive-dropdown.component.scss'],
  providers: [
    {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => ReactiveDropdownComponent),
        multi: true,
    }
]
})
export class ReactiveDropdownComponent extends BaseFormField implements ControlValueAccessor {

    options: FlexFormDropdownData[] = [];
    grooveOptions: DropdownOptions[] = [];
    selectedText: string = null;

    _field: FlexFormField;

    @Input()
    get field(): FlexFormField {
        return this._field;
    }

    set field(newField: FlexFormField) {
        this._field = newField;
        this.options = newField?.values as FlexFormDropdownData[];
        this.grooveOptions = this.options.map((option: FlexFormDropdownData) => {
            return { text: option.name };
        });

        if (newField?.initialValue) {
            this.selectedText = this.getOptionById(newField?.initialValue)?.name;
        }
    }

    onChange(text: any) {
        this.selectedText = text;
        let id = this.options?.find((option) => {
            return option?.name === text;
        })?.id;

        this.propagateChange(id);
    }

    onTouch() {
        this.propagateTouch();
    }

    writeValue(newId: string) {
        this.selectedText = this.getOptionById(newId)?.name;
    }

    getOptionById(id: string): FlexFormDropdownData {
        return this.options?.find((option) => {
            return option?.id === id;
        });
    }
}
